/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconCloseElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-close';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M13.06,12l5.47-5.47a.75.75,0,0,0-1.06-1.06L12,10.94,6.53,5.47A.75.75,0,0,0,5.47,6.53L10.94,12,5.47,17.47a.75.75,0,1,0,1.06,1.06L12,13.06l5.47,5.47a.75.75,0,0,0,1.06-1.06Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconCloseElement.is,
    window.JhaIconCloseElement);
export default window.JhaIconCloseElement;
